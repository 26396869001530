.forum-title{
    color: #000000;
    margin-bottom: 0px;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: bold;
}

.notes-text{
    color: #000000;
    margin-bottom: 0px;
    font-size: 12px;
    font-family: 'Lato';
}