.events li {
    display: flex;
    color: #999;
    width: 100%;
}

.events time {
    position: relative;
    padding: 0 1.5em;
}

.events time::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 15px;
    top: -11px;
    transform: translateX(50%);
    border-radius: 50%;
    background: #fff;
    border: 1px #ccc solid;
    width: .8em;
    height: .8em;
}


.events span {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
}

.events span::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #ccc solid;
}

.events {
    margin: 1em;
    width: 100%;
}

.events,
.events *::before,
.events *::after {
    box-sizing: border-box;
    font-family: arial;
}

/* Global CSS, you probably don't need that */

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
}

.container {
    font-family: 'Lato', sans-serif;
    width: 1000px;
    margin: 0 auto;
}

.wrapper {
    display: table-cell;
    height: 400px;
    vertical-align: middle;
}

/* .nav {
    margin-top: 40px;
} */

.pull-right {
    float: right;
}

a, a:active {
    color: #333;
    text-decoration: none;
}

a:hover {
    color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 3px;
    padding: 10px 10px 10px 30px;
    min-width: 180px;
    float: left;
    position: relative;
    background-color: #d9e3f7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid rgba(240, 103, 124, 0.7);
    z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
background: linear-gradient(
118deg, #f06767, rgba(240, 103, 124, 0.7));
}

.arrow-steps .step.current:after {
    border-left: 17px solid 118deg, #f06767, rgba(240, 103, 124, 0.7));;
}
.current { padding: 8px !important}