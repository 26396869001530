.section-box{
    background-color: #f5f5f5;
    /* padding: 15px; */
}

.section-item{
    all: unset;
    display: block;
    font-size: 14px;
    /* border: 1px solid #ccc; */
    width: 100%;
    /* padding: 10px 10px; */
    border-bottom: 1px solid #059adb;
  }

  .section-item.active{
    all: unset;
    display: block;
    font-size: 14px;
    background-color: #059adb;
    color: #ffffff;
    /* border: 1px solid #ccc; */
    width: 100%;
    /* padding: 10px 10px; */
    border-bottom: 1px solid #059adb;
  }