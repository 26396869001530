@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
a {
  color: #254181;
  text-decoration: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
a:focus-visible {
  outline: none;
}

.right-block {
  padding-left: 250px;
}
.content-wrapper {
  padding: 10px;
  /* margin-top: 100px; */
  padding-top: 120px!important;
}

/* Vikas CSS */

.listingpage-title{
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}

.new-content-section{
  background-color: #ffffff;
  padding: 15px;
  font-family: 'Lato', sans-serif;
}

.new-content-section button{
  padding: 5px;
}

.new-content-section input, .new-content-section select {
  font-size: 13px;    
  height: 36px!important;
  padding: 0px 5px!important;
  border-radius: 0px!important;
  margin-right: 10px;
}

.new-content-section input[type='checkbox'] {
  height: 20px!important;
  width: 20px!important;
}

.page-title-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page-title-container > p{
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}

.page-title-right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.primary-plus-button{
  all: unset;
  cursor: pointer;
  height: 20px;
  background-color: #3399cc;
  color: #ffffff;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.secondary-button{
  all: unset;
  cursor: pointer;
  height: 20px;
  background-color: #222d32;
  color: #ffffff;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.primary-btn-icon{
  font-size: 15px;
}

.underline{
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
}

.underline-dashed{
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  border-bottom-style: dashed;
}

.spacer{width: 10px;}

.search-row{
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.search-form-element{
  font-size: 13px;    
  height: 30px!important;
  padding: 0px 10px!important;
  border-radius: 0px!important;
  width: 150px;
  margin-right: 10px;
}

.alphabet-filter{
  display: flex;
  margin-top: 8px;
}

.alpha-filter{
  all:unset;
  cursor: pointer;
  font-size: 12px;
  color: #222d32;
  border: 1px solid #ccc;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alpha-filter.active{
  all:unset;
  background-color: #3399cc;
  cursor: pointer;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid #3399cc;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-div{
  display: flex;
}

.tab-div-btn{
  all: unset;
  cursor: pointer;
  height: 20px;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff;
  color: #222d32;
  font-size: 16px;
  display: flex;
  align-items: center;
  /* width: 180px; */
  padding: 5px 20px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
}

.tab-div-btn.active{
  all: unset;
  cursor: pointer;
  height: 20px;
  border: 1px solid #3399cc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #3399cc;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
  /* width: 180px; */
  padding: 5px 20px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
}

.ck-content{
  height: 260px;
}

.new-content-section table tr {
  padding: 5px;
  border: 0px;
  border-bottom: 1px solid #f5f5f5;
}

.new-content-section table tr th {
  padding: 5px;
  border: 1px solid #ccc;
  /*  */
}

.new-content-section table tr th > p{
  font-size: 13px!important;
  margin-bottom: 0px;
  /*  */
}

.new-content-section table tr td {
  padding: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.new-content-section table tr td:first-child{
  background-color: #ececec;
  /* display: flex;
  width: 100%;
  height: 100%; */
  /* justify-content: flex-end;
  align-items: flex-end; */
  text-align: left;
  padding: 0px 5px;
  font-weight: bold;
  width: 15%;
}

.new-content-section table tr td:not(:first-child){
  width: 80%;
  background-color: #f5f5f5;
}

.new-content-section table tr td:not(:first-child) div{
  /* display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px; */
}

.mandatory{
  color: #ff0000;
}

.fixed-author-list{
  background-color: #ffffff;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 10px);
  top: 40px;
  border: 1px solid #ccc;
  border-top: 0px;
  flex-direction: column;
  padding: 0px!important;
}

.option-btn{
  all: unset;
  cursor: pointer;
  height: 20px;
  border-top: 1px solid #ccc;
  background-color: #ffffff;
  color: #222d32;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Lato';
  width: calc(100% - 10px);
}
