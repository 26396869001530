.headerTxt {
  width: 400px;
  background-color: #22b1e8;
  border-radius: 100px;
  text-align: center;
  padding: 10px;
}
.left_logo {
  height: 110px;
}
.right_logo {
  height: 70px;
}
.top-bar {
  position: fixed;
  height: 100px !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border-bottom: 2px solid #5ac5d0; */
}

.pcoded-navbar {
  height: calc(100vh - 100px) !important;
  position: absolute;
}

.sideNavBar {
  width: 250px;
  background-color: #f2f4f3;
  position: fixed;
  overflow-y: scroll;
  top: 100px;
  bottom: 0;
}

.sideNavBar::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
}
.sideNavBar::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.sideNavBar::-webkit-scrollbar-thumb {
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 2px solid #5ac5d0; /* creates padding around scroll thumb */
}

.sideMenuCap {
  background-color: #5bc5d0;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  padding: 5px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.mainMenu {
  background-color: #d2eefa;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;

  box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
}

.menuNo {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #0596dc;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuNo2 {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #56b4be;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  width: calc(100% - 35px);
}

.menuTitle > p {
  font-size: 16px;
  font-weight: bold;
  color: #496778;
}

.menuEnd {
  width: 5px;
  background-color: #0596dc;
}

.subMenuSection {
  padding: 0px 10px;
}

.subMenu {
  background-color: #22b1e8;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 8px auto;
  text-align: center;
}
.redMenu {
  background-color: #f31616;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 8px auto;
  text-align: center;
}
.greenMenu {
  background-color: #56b4be;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 8px auto;
  text-align: center;
}
.currentMenu {
  background-color: #333333;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 8px auto;
  text-align: center;
}

.subMenu > p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
}
.redMenu > p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
}

.card{
  border: 1px solid #22b1e8!important
}
.card-header{
  background-color: #22b1e8;
  color: #ffffff;
}

.card-title{
  color: #496778;
  margin-bottom: 0;
  font-weight: bold;
}

.displayCard{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  padding: 15px
}

.displayCardBody{
  padding:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
  color: #22b1e8;
}

.displayCardFooter{
  padding:15px;
  /* color: #22b1e8; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
}

.customTable{
  width: 100%
}
.customHeader{
  background-color: #22b1e8;
  color: #ffffff;
}

.customTable > thead > tr > th, .customTable > tbody > tr > td {
  padding: 10px;
  border : 1px solid #22b1e8;
}

.cardEditButton{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  cursor: pointer;
}

/* Instituion Edit */

.instituionTabSection, .instituionTabSection:hover, .instituionTabSection:active, .instituionTabSection:focus{
  all: unset;
  padding: 15px 25px;
  color: #000;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0px 0px;
  box-shadow:none!important;
  margin-right: 5px;
  background-color: #ccc;
}

.instituionTabSection.active{
  border: 1px solid #22b1e8;
  border-radius: 10px 10px 0px 0px;
  background-color: #22b1e8!important;
  color: #ffffff;
}

.rangeBox{
  margin-top: 10px;
  border: 1px solid #ccc;
  border-top: 5px solid #22b1e8;
  border-radius: 5px;
  margin-right: 0;
  margin-left: 0;
}

.form-input{
  width: 100%;
  height: 32px;
  margin: 5px;
  border: 1px solid #e6e6e6;
}

.form-input:focus-visible{
  border: 1px solid #e6e6e6!important;
  outline: none!important;
}

.rowActionButton{
  height: 32px;
  width: 32px;
  padding: 0px;
  border-radius: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.tableRow{
  border-bottom: 1px solid #ccc;
}