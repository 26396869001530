p {
  font-family: 'Lato';
}



.login-page{
  min-height: 100vh;
  background-color: #0c66a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box{
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-logo{
  width: 120px;
}

.login-page-label{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.loginpage-input{
  width: 100%;
  height: 40px!important;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
}

.loginpage-heading{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-style: italic;
}

.user-primary-btn{
  all: unset;
  background-color: #0c66a6; 
  color: #ffffff;
  font-size: 16px;
  padding: 5px 20px;
  border-radius: 50px;
  width: 120px;
  text-align: center; 
  font-weight: bold;
}

.social-media-buttons{
  all: unset;
  border: 1px solid #0c66a6; 
  color: #000000;
  font-size: 14px;
  border-radius: 50px;
  width: 150px;
  text-align: center;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-buttons img{
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.tablink{
  margin: 0px 10px;
  text-decoration: none;
  color: #ccc;
  font-size: 12px;
}

.tablink.active{
  margin: 0px 10px;
  color: #0c66a6;
  font-weight: bold;
  text-decoration: none;
}

.tnc-text{
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
  margin-left: 10px;
}




a {
  text-decoration: none;
}

/* nav {
  font-family: monospace;
} */

.fullwidth-menu ul {
  /* background: darkorange; */
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.fullwidth-menu li {
  color: #000000;
  /* background: darkorange; */
  display: block;
  float: left;
  padding: 1.2rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}
  
.fullwidth-menu li a {
  color: #000000;
}

.fullwidth-menu li:hover,
.fullwidth-menu li:focus-within, li.active {
  background: #0c66a6;
  cursor: pointer;
}

.fullwidth-menu li:hover a, .fullwidth-menu li:focus-within a, .fullwidth-menu li.active a {
  outline: none;
  color: #ffffff;
  /* padding: 1.2rem; */
}

.fullwidth-menu ul li ul {
  /* background: orange; */
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

.fullwidth-menu ul li:hover > ul,
.fullwidth-menu ul li:focus-within > ul,
.fullwidth-menu ul li ul:hover,
.fullwidth-menu ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
  background-color: #ffffff;
  border:1px solid #ccc; 
}

.fullwidth-menu ul li ul {
  clear: both;
  width: 100%;
}

.fullwidth-menu ul li ul li {
  clear: both;
  width: 100%;
}

.fullwidth-menu ul li:hover ul li > a {
  color: #000000;
}

.fullwidth-menu ul li:hover ul li:hover > a {
  color: #ffffff;
}

.sticky-header{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
}



.fullwidth-menu{
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.main-searchbox-div{
  display: flex;
}

.main-searchbox-div button{
  all: unset;
  height: 36px;
  width: 100px;
  padding: 0px 15px;
  background-color: #0c66a6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Lato';
}

.header-search-box{
  width: 100%;
}

.header-logo{
  width: 200px;
  margin: 0px 0px;
}

.header-logo-iadval{
  width: 100px;
  margin: 0px 0px;
}

.header-logo-kb{
  width: 200px;
  margin: 0px 0px;
}

.secondary-header a{
  color: #ffffff;
  font-size: 11px;
}

.menu-divider{
  width: 1px;
  border-left: 1px solid #ffffff;
  height: 20px;
  margin: 0px 20px;
}

.usergreeting-div{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0px;
}

.usergreeting-text{
  font-size: 11px;
  margin: 0px;
  color: #000000;
  font-weight: bold;
  margin-left: 5px;
}

#dropdown-basic{
  all: unset;
  font-size: 13px;
}

/* Subject Listing */
.subject-thumbnail-div{
  /* background-color: #f6f6f6; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.subject-thumbnail-img-div{
  background-color: #f5f5f5;
  /* min-height: 180px; */
}

.subject-thumbnail-img-div > img{
  background-color: #f6f6f6;
  /* min-height: 180px; */
  object-fit: cover;
  width: 100%;
}

.suject-thumbnail-category{
  font-size: 10px;
  color: #000000;
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 5px;
}

.suject-thumbnail-name > a{
  font-size: 18px;
  color: #0c66a6!important;
  font-weight: bold;
  margin-bottom: 5px;
}

.author-name{
  margin-bottom: 0px;
  font-size: 12px;
  margin-left: 5px;
}

.book-preface-text{
  font-size: 10px;
  color: #000000;
  margin-bottom: 5px;
}

.assessment-setup-box{
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  margin: 0px;
  margin-top: 15px;
}

.assessment-setup-box p{
  margin-bottom: 5px;
  margin-top: 15px!important;
  font-size: 13px;

}