.bg_admin {
  background-image: url('../assets/admin.jpg');
  height: calc(100vh);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
body {
  font-family: 'Lato', sans-serif;
}
body,
html,
.container,
#root {
  height: 100%;
}

.rdt_Table{
  border-bottom: 1px solid #ccc;
}

.rdt_TableHead{
  padding: 0px!important;
  /* border: 1px solid #ccc!important; */
  background-color: #f5f5f5!important;
}

.rdt_TableHeadRow{
  background-color: #f5f5f5!important;
}

.rdt_TableCol{
  /* border: 1px solid #ccc!important; */
  border-bottom: 0px!important;
}

.rdt_TableBody{
  padding: 0px!important;
}

.rdt_TableCell{
  border: 1px solid #ccc;
  border-bottom: 0px!important;
}