.customTable{
    width: 100%
}
.customHeader{
    background-color: #22b1e8;
    color: #ffffff;
}

.customTable > thead > tr > th, .customTable > tbody > tr > td {
    padding: 10px;
    border : 1px solid #22b1e8;
}
