.homepage{
  position: relative;
  max-height: 100vh;
  overflow-y: hidden;
}
.homepage-img{
  width: 100%;
  /* height: 100vh; */
  object-fit: contain;
}

.home-actionbar{
  position: fixed;
  bottom: 50px;
  width: 100%;
}

.home-link-box{
  background-color: #601c3b;
  padding: 15px;
  width: 300px;
  height: 150px;
}

.linkaction-button{
  all: unset;
  color: #fcc131;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  height: 100%;
}

.login-form-label{
  color: #fcc131;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: 0px;
}

.login-form-input, .login-form-input:focus-visible{
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #fcc131;
  border-style: dashed;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
  width: 100%;
}

.modal-input{
  background-color: transparent;
  border: 0px;
  border: 1px solid #000000;
  height: 36px;
  outline: none;
  color: #000000;
  font-size: 14px;
  width: 100%;
}

.login-input-instruction{
  color: #fcc131;
  margin-top: 5px;
  font-size: 10px;
  white-space: nowrap;
  margin-bottom: 0px;
}

.login-submit-button{
  all: unset;
  background-color: #fcc131;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #601c3b;
  width: 100%;
  margin-top: 10px;
  padding: 5px 0px;
}

.secondary-button{
  all: unset;
  background-color: #f6f6f6;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #601c3b;
  width: 100%;
  margin-top: 10px;
  padding: 5px 0px;
  border: 1px solid #b1b1b1; 
}

.fixHeader {
  height: 75px;
}
.fixBody {
  height: calc(100vh - 75px);
  overflow: hidden;
  overflow-y: auto;
}

@media screen and (min-width: 320px) and (max-width: 620px) {
  .fixHeader {
    height: 130px;
  }
  .fixBody {
    height: calc(100vh - 130px);
    overflow: hidden;
    overflow-y: auto;
  }
}