.centerAlignContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.none {
  display: none;
}

.myBtn:hover {
  background-color: #555;
}

.sectionHeading {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
}
.sectionHeadingBox {
  background-image: linear-gradient(180deg, #f58738 50%, #f69e5c 50%);
  /* background-color: #f2a736;  // */
  padding: 5px 30px;
  border-radius: 10px;
}
.sectionHeadingText {
  font-size: 25px;
  margin-bottom: 0px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
}

.editorBox {
  background-color: #0c66a6;
  padding: 5px 30px;
  border-radius: 10px;
}

.editorBoxText {
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 0px;
}

.editorImageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -70px;
}

.editorImage {
  border-radius: 70px;
  height: 140px;
  width: 140px;
  border: 2px solid #0c66a6;
  background-color: #ebecec;
}

.contentCard {
  border-radius: 10px;
  padding: 10px;
  padding-top: 80px;
  margin-bottom: 15px;
  background-color: #ebecec;
  box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 250px;
}
.contentCard2 {
  border-radius: 10px;
  background-color: #ebecec;
  box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  text-align: center;
  min-height: 105px;
  padding: 15px;
}

.partner1 {
  width: 120px;
}

.partner2 {
  width: 180px;
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .partner1 {
    width: 60px;
  }

  .partner2 {
    width: 90px;
  }

  .contentCard {
    height: 300px;
  }
}

.editorName {
  color: #0c66a6;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: bold;
}
.subEditorName {
  color: #0c66a6;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
}

.editorDescription {
  color: #00001d;
  font-size: 15px;
  margin-bottom: 0;
}
.subEditorDescription {
  color: #00001d;
  font-size: 12px;
  margin-bottom: 0;
}
.sectionEditorsBox {
  border-radius: 10px;
  padding: 10px;
  padding-top: 100px;
  margin-bottom: 15px;
  margin-right: 25px;
  margin-left: 25px;
  background-color: #ebecec;
  box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px 8px 3px -4px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 460px;
  overflow: auto;
}

@media screen and (min-width: 1600px) {
  .sectionEditorsBox {
    height: 410px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .sectionEditorsBox {
    height: 430px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .sectionEditorsBox {
    height: 420px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .sectionEditorsBox {
    height: 420px;
  }
}

/* @media screen and (min-width: 576) and (max-width: 767px) {

    .sectionEditorsBox{
        height: 680px;
    }
}  */

@media screen and (max-width: 767) {
  .sectionEditorsBox {
    height: 380 !important;
  }
}

.sectionEditorImageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectionSubEditorImage {
  border-radius: 60px;
  height: 100px;
  width: 100px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.sectionEditorImage {
  border-radius: 60px;
  height: 120px;
  width: 120px;
  border: 1px solid #ccc;
  /* background-color: #ebecec; */
}

.wrapperDiv {
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  border-radius: 35px;
  width: 100%;
  padding: 5px;
  padding-left: 85px;
  text-align: left;
  background-image: linear-gradient(to right, #0f9db4, #3ca766);
}
.wrapperImage {
  height: 80px;
  width: 80px;
  border-radius: 40px;
}

.wrapperDivText {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}
.wrapperDivText2 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
}

.wrapperImageDiv {
  position: absolute;
  top: -20px;
  left: -5px;
}

.wrapperFixBox {
  position: absolute;
  top: 30px;
  width: 100%;
}
.sectionEditorList {
  position: relative;
}

.whatweoffer {
  background-color: #0c66a6;
  padding-top: 40px;
  padding-bottom: 40px;
}

.offerDiv {
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}

.offerDivTextDiv {
  background-color: #ffffff;
  padding: 8px 15px;
}

.offerDivText {
  color: #000000;
  font-size: 18px;
  margin-bottom: 0;
}

.offerDivBlankDiv {
  background-color: #dae1ed;
  height: 10px;
}

.offerPlainText {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.viewAllButtons {
  all: unset;
  border-radius: 26px;
  background-color: #5ca64f;
  color: #ffffff;
  font-size: 13px;
  height: 26px;
  padding: 5px 15px;
  border: 1px solid #fff;
}

.actionButtonDiv {
  /* width: 100%; */
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.benefitsImages {
  height: 100px;
}

.benefitsCard {
  padding: 20px;
  padding-top: 50px;
  margin-top: -50px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #d2f1fb, #ffffff, #d2f1fb);
  box-shadow: 2px 12px 3px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 12px 3px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 12px 3px -4px rgba(0, 0, 0, 0.4);
  min-height: 200px;
  margin-bottom: 10px;
}

.benefitsText {
  color: #000000;
  font-size: 18px;
  margin-bottom: 0;
}
.aboutderma {
  /* background-color: #d0f1fb; */
  background-image: linear-gradient(
    to right,
    #d0f1fb,
    #d0f1fb,
    #ffffff,
    #d0f1fb,
    #d0f1fb
  );
  padding-top: 30px;
  padding-bottom: 15px;
}

.aboutDermaText {
  color: #000000;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 25px;
  text-align: center;
}

.platform {
  background-image: linear-gradient(
    to right,
    #d0f1fb,
    #d0f1fb,
    #ffffff,
    #d0f1fb,
    #d0f1fb
  );
  padding-top: 30px;
  padding-bottom: 15px;
}

.platformHeadingText {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 25px;
  text-align: center;
}

.dynamismImage {
  width: 100%;
}

.activeDivider {
  display: flex;
  height: calc(100vw / 3);
}

.platformLabelDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* flex: 1 1; */
}

.platformLabelDiv.div1 {
  height: calc((100vw / 3) / 2.5);
  justify-content: center;
}

.platformLabelDiv.div2 {
  height: calc((100vw / 3) / 2);
}

.labelDiv {
  background-color: #f2a736;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
}

.labelSubHeading {
  color: #000000;
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
}
.small-sc {
  display: none;
}
.big-sc {
  background-image: linear-gradient(
    to right,
    #d0f1fb,
    #d0f1fb,
    #ffffff,
    #d0f1fb,
    #d0f1fb
  );
}

.sliderBg {
  height: calc(100vw / 3.05);
}
@media screen and (max-width: 767px) {
  .activeDivider {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .small-sc {
    display: block;
  }
  .big-sc {
    display: none;
  }
}

.inovativeplatform {
  background-color: #09275b;
  padding: 10px;
}
.inovativeplatformText {
  color: #ffffff;
  font-size: 25px;
  margin-bottom: 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .inovativeplatformText {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
  }
}
.firstLogo {
  /* width: 75%; */
  height: calc((100vw / 3) / 2);
}

.secondLogo {
  width: 75%;
}

@media screen and (max-width: 767px) {
  .firstLogo {
    width: 100%;
  }

  .secondLogo {
    width: 100%;
  }
}

.fullWidthDiv {
  display: flex;
  align-items: center;
}

.coloredCircle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0c66a6;
}
.coloredLine {
  width: 1px;
  height: 1px;
  width: 100%;
  background-color: #0c66a6;
}
.coloredCircle.left {
  float: left;
}
.coloredCircle.right {
  float: right;
}

.offerImages {
  /* width: 80%; */
  height: 120px;
}

.dimCard {
  background-image: linear-gradient(#0f578b, #0e65a4);
  padding: 15px;
  padding-top: 60px;
  border-radius: 10px;
  margin-top: -60px;
  min-height: 250px;
}

@media screen and (min-width: 992px) and (max-width: 1199) {
  .dimCard {
    background-image: linear-gradient(#0f578b, #0e65a4);
    padding: 15px;
    padding-top: 40px;
    border-radius: 10px;
    margin-top: -40px;
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .offerImages {
    /* width: 80%; */
    height: 80px;
  }

  .dimCard {
    background-image: linear-gradient(#0f578b, #0e65a4);
    padding: 15px;
    padding-top: 40px;
    border-radius: 10px;
    margin-top: -40px;
    height: auto;
  }
}

.topArrowBtn{
  padding: 0px;
    height: 60px;
    width: 60px;
    border-radius: 30px!important;
    position: fixed;
    bottom: 70px;
    right: 100px;
}

.newBarBoxes{
  padding: 15px;
  min-height: 300px;
  margin-top: 15px;
}

.newBarBoxes > .image-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 90px;
  width: 90px;
  border-radius: 45px;
  margin-left: auto;
  margin-right: auto;
}

.newBarBoxes > .image-div > img{
  height: 50px;
  width: 50px;
}

.newBarBoxes > .title{
  color: #ffff00;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.newBarBoxes > .subTitle{
  color: #ffffff;
  font-size: 18px;
  text-align: center;
}

.like-green{
  background-color: #88c549;
}

.like-blue{
  background-color: #0080c3;
}

.like-green2{
  background-color: #2da879;
}

.like-blue2{
  background-color: #45b5c2;
}

.offer-div{
  display: flex;
  background-color: rgba(0,0,0,0.3);
  padding: 15px;
  border-radius: 5px;
}

.offer-div > .image-div{
  background-color: #ffffff;
  height: 120px;
  width: 120px;
  min-height: 120px;
  min-width: 120px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-div > .image-div > img{
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.offer-div > .content-div{
  padding-left: 15px;
}

.offer-div .title{
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 2px;
} 

.offer-div .description{
  color: #ffffff;
  font-size: 13px;
  margin-bottom: 0px;
  letter-spacing: 1px;
} 

.editors-intro-text{
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  margin-top: 15px;
  text-align: center;
}

.editor-in-chief{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}


.editor-in-chief .title{
  color: #c00052;
  font-size: 20px;
  font-weight:bold;
  letter-spacing: 2px;
  margin-top: 15px;
}

.editor-in-chief img{
  height: 180px;
  width: 180px;
  object-fit: contain;
}

.editor-in-chief .name{
  color: #0c66a9;
  font-size: 16px;
  font-weight:bold;
  letter-spacing: 1px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.editor-in-chief .description{
  color: #1b1b1b;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  text-align: center;
}

.editor-msg{
  color: #1b1b1b;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  font-style: italic;
}

.associate-editors{
  
}

.associate-editors .title{
  color: #0c66a9;
  font-size: 20px;
  font-weight:bold;
  letter-spacing: 2px;
  margin-top: 15px;
  text-align: center;
}

.associate-editor{
  display: flex;
}

.associate-editor img{
  height: 180px;
  width: 180px;
  object-fit: contain;
}

.associate-editor .name{
  color: #c00052;
  font-size: 14px;
  font-weight:bold;
  letter-spacing: 1px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.associate-editor .description{
  color: #1b1b1b;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.section-editors{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}


.section-editors .title{
  color: #c00052;
  font-size: 20px;
  font-weight:bold;
  letter-spacing: 2px;
  margin-top: 15px;
}

.section-editor{
  border: 5px solid #f5f5f5;
  margin-top: 15px;
}

.section-editor .category{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
  padding: 5px;
}

.section-editor .category > img{
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.section-editor .category > p{
  margin-bottom: 0px;
}

.section-editor .doctor{
  display: flex;
}

.section-editor .doctor img{
  height: 80px;
  width: 80px;
  margin-right: 10px;
}

.section-editor .doctor .name{
  color: #c00052;
  font-size: 12px;
  font-weight:bold;
  letter-spacing: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.section-editor .doctor .description{
  color: #1b1b1b;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}