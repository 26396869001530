/* Subject Listing */
.news-thumbnail-div{
    /* background-color: #f6f6f6; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .news-thumbnail-img-div{
    background-color: #f5f5f5;
    min-height: 120px;
  }
  
  .news-thumbnail-img-div > img{
    background-color: #f6f6f6;
    /* min-height: 180px; */
    object-fit: contain;
    width: 100%;
    height: 120px;
  }
  
  .news-thumbnail-category{
    font-size: 10px;
    color: #000000;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .news-thumbnail-name > a{
    font-size: 18px;
    color: #0c66a6!important;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .news-subject-item{
    all: unset;
    font-size: 14px;
    /* border: 1px solid #ccc; */
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #059adb;
  }

  .news-title{
    font-size: 22px;
    color: #0c66a6!important;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .news-subtitle{
    font-size: 18px;
    color: #b1b1b1!important;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .imageBox{
    height: 180px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageBox img{
    height: 180px;
    object-fit: contain;
  }

  .count-div{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 15px;
  }

  .count-div p{
    margin-bottom: 0px;
    margin-left: 5px;
  }

  .like-btn{
    all: unset;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 14px;
    color: #ccc;
    justify-content: center;
    align-items: center;
  }

  .liked-btn{
    all: unset;
    border: 1px solid #ff0000;
    padding: 5px 10px;
    font-size: 14px;
    color: #ff0000;
    justify-content: center;
    align-items: center;
  }

  .search-round-div{
    height: 36px;
    border: 1px solid #000000;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    background-color: #225ba7;
  }

  .search-round-div > input, .search-round-div > input:focus-visible{
    height: 34px;
    border: none!important;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
    outline: none!important;
  }

  .search-round-div > button{
    all: unset;
    height: 34px;
    background-color: #225ba7;
    color: #ffffff;
    padding: 0px 10px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .related-news-title{
    font-size: 18px;
    color: #000000!important;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
  }
