.image-btn{
    all:unset;
    padding: 0px 10px;
    background-color: #f5f5f5;
    height: 36px;
    margin: 0px 5px;
    cursor: pointer;
}

.image-btn.active{
    all:unset;
    padding: 0px 10px;
    background-color: #f5f5f5;
    height: 36px;
    margin: 0px 5px;
    cursor: pointer;
    border:1px solid #000000;
}

.course-thumbnail-img-div{
    background-color: #f5f5f5;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-thumbnail-img-div > img{
    height: 180px;
    object-fit: cover;
}

.section-btn-div.active{
    background-color: #225ba7;
    color: #ffffff;
}

.section-btn-div.active button{
    color: #ffffff!important;
}

.plain-button{
    all:unset;
    /* padding: 0px 10px; */
    /* background-color: #f5f5f5; */
    height: 30px;
    margin-right: 30px;
    cursor: pointer;
    font-size: 14px;
    color: #225ba7;
    font-weight: bold;
}

.lesson-listing button{
    padding-left: 25px;
    border-bottom: 0px;
    color: #000000;
    font-size: 12px;
}

.video-box-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.notes-title{
    color: #000000;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: bold;
}

.notes-text{
    color: #000000;
    margin-bottom: 0px;
    font-size: 12px;
    font-family: 'Lato';
}

.notes-section{
    right: 15px;
    position: absolute;
    flex: 1; 
    border:1px solid #cccccc;
    padding:15px;
    background-color: #ffffff;
    width: 300px;
    min-height: 500px;
}