.exam-header{
    background-color: #fff!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.exam-background{
    background: #f5f5f5;
}

.question-box{
    background-color: #fff!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.top-line{
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px -1px 3px; */
    padding-top: 1rem;
}

.legend-btn{
  all: unset;
  cursor: pointer;
}

.attempted{
    padding-top:2px;
    padding-bottom:2px;
    width: calc(20% - 4px);
    margin:2px;
    text-align:center;
    border-radius:50rem;
    font-weight: 500;
    font-size: 1rem;
    color: #fff;
    background: #198754;
}

.unattempted{
    padding-top:2px;
    padding-bottom:2px;
    width: calc(20% - 4px);
    border:1px solid #dee2e6;
    margin:2px;
    text-align:center;
    border-radius:50rem;
    font-weight: 500;
    font-size: 1rem;
}

.toggle-btn{
    background: #fff;
    border: 1px solid #000; 
    color: #000;
}

.toggle-btn:hover{
    background: #000;
    border: 1px solid #000; 
    color: #fff;
}
.finish-btn{
    background: #fff;
    border: 1px solid #336699; 
    color: #336699;
}

.login-screen-description-text{
    font-size: 16px!important;
    text-align: left;
    margin-bottom: 15px;
}

.bg-candidate {
    /* background-image: url("./imgs/assessment.jpg"); */
    height: calc(100vh);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.white-box{
    background-color: #ffffff;
    padding:25px;
    border-radius: 15px;
    margin-left: 15px;
}

.logo-div{
    text-align: center;
    margin-top: 25px;
}

/* Feedback screen */
.feedbackPage{
    background-color: #f5f5f5;
}

.singleQuestion{
    background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.75);
    /* padding:20px; */
    margin-bottom: 15px;
}

.questionNumber{
    background-color: #e6f9ff;
    padding-top:10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
}
.questionNumber p{margin-bottom: 0px;}
.questionPart{
    background-color: #e6f9ff;
    padding:10px;
    padding-left: 20px;
    padding-right: 20px;
}
.answerSection{
    padding:10px;
    padding-left: 20px;
}

.exam-page{
    height: 100vh;
    /* margin: 10px;
    border: 5px solid #0991f7; */
  }
  
  .exam-palette{
    background-color: #e6f4fe;
    padding: 15px;
    position: relative;
  }
  
  .testname-heading{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
  }
  
  .test-navigation-div{
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 5px;
    
  }
  
  .exam-section{
    background-color: #e6f4fe;
    color: #000000;
    box-shadow: none;
    border: 1px solid #e6f4fe;
    margin: 5px;
    font-size: 14px;
  }
  
  .exam-section.active{
    background-color: #0991f7;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #0991f7;
    margin: 5px;
  }
  
  .test-content-div{
    margin-top: 15px;
    border: 5px solid #ccc;
    padding: 5px;
  }
  
  .test-content-section{
    height: 90vh;
  }
  
  .question-bar{
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  
  .mark-for-review{
    background-color: #e6f4fe;
    color: #000000;
    box-shadow: none;
    border: 1px solid #209bf8;
    margin: 5px;
    font-size: 16px;
    padding:10px
  }
  
  .go-next{
    background-color: #f5f5f5;
    color: #000000;
    box-shadow: none;
    border: 1px solid #cccccc;
    margin: 5px;
    font-size: 16px;
    padding:10px;
    width: 120px;
  }
  
  .save-next{
    background-color: #209bf8;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #209bf8;
    margin: 5px;
    font-size: 16px;
    padding:10px
  }
  
  .not-visited {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #ccc;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .not-answered {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #ff9999;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .marked {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #4d88ff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .answered {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #4caf50;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .exam-container{
    padding: 15px;
  }
  
  .score-box{
    background-color: #E06D99;
    padding:25px 15px;
    border-radius: 10px;
    position: relative;
  }
  
  .negative-marks{
    background-color: #64A3F3;
    padding:25px 15px;
    border-radius: 10px;
    position: relative;
  }
  .average-time{
    background-color: #F19D49;
    padding:25px 15px;
    border-radius: 10px;
    position: relative;
  }
  
  
  .accuracy{
    background-color: #96C15C;
    padding:25px 15px;
    border-radius: 10px;
    position: relative;
  }
  
  .boxValue{
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
  }
  
  .boxLabel{
    margin-bottom: 0px;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
  }
  
  .fix-pos{
    color:#ffffff;
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 35px;
  }
  
  .review-section-heading{
    font-weight: bold;
    color: #000000;
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  
  .scorecard-table-heading{
    font-weight: bold;
    color: #000000;
    font-size: 15px;
    margin-bottom: 0px;
  }
  
  .scorecard-table-value{
    color: #000000;
    font-size: 15px;
    margin-bottom: 0px;
    font-weight: 500 ;
  }
  
  .review-sections.active{
    background-color: #3578F6;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #3578F6;
    margin: 5px;
    font-size: 14px;
    padding:5px;
    font-weight: bold;
  }
  
  .review-sections{
    background-color: #EEEEEE;
    color: #000000;
    box-shadow: none;
    margin: 5px;
    font-size: 14px;
    padding:5px;
    border:none;
    font-weight: bold;
  }
  
  .tableHeaderDiv{
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  .marked-as-review{
    border: none;
    background-color: #ccc;
    color: #000000;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    float: right;
  }
  
  .tab-btn{
    border: none;
    width: 100%;
    height: 50px;
    font-weight: bold;
    border: 1px solid #3578F6;
    color: #3578F6;
    background-color: #ffffff;
  }
  
  .tab-btn.active{
    border: none;
    width: 100%;
    height: 50px;
    font-weight: bold;
    border: 1px solid #3578F6;
    color: #ffffff;
    background-color: #3578F6;
  }
  
  .divider-div{
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }