/* primary color: #225ba7 */
/* secondary color: #059adb */

.theme-bg {
    background-color: #059adb!important;
}

.white{
    color: #ffffff;
}

.padding-vertical-15{
    padding-top: 15px;
    padding-bottom: 15px;
}

.authors{
    padding-left: 15px;
    display: flex;
    color: #000000;
}
.authors > div{
    margin-bottom: 10px;
    font-size: 15px;
    font-family: 'Lato';
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    border-right: 1px solid #000000;
} 

.author-pen {
    color: #225ba7;
    font-size: 16px;
    margin-right: 5px;
} 

.dashboard-tab-btn, .dashboard-tab-btn:hover{
    all:unset;
    background-color: #ffffff;
    color: #000000;
    border-radius: 0px!important;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f5f5;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
  }
  
  .dashboard-tab-btn.active, .dashboard-tab-btn.active:hover, .dashboard-tab-btn:active{
    all:unset;
    background-color: #225ba7;
    color: #ffffff!important;
    border-radius: 0px!important;
    padding: 15px 20px;
    /* width: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
    white-space: nowrap;
  }

  .label-2{
    font-size: 24px;
    font-family: 'Lato';
    font-weight: bold;
}

.label-6{
    font-size: 12px;
    font-family: 'Lato';
}

.mb-5{
    margin-bottom: 5px!important;
}

.content-box{
    background-color: #f5f5f5;
    border: 1px solid #225ba7;
    border-top: 3px solid #225ba7;
    min-height: 500px;
}

.chapter-content-box{
    background-color: #f5f5f5;
    border: 1px solid #225ba7;
    border-top: 3px solid #225ba7;
    height: 600px;
    overflow: hidden;
    position: relative;
}

.chapter-btn{
    display: flex;
    all: unset;
    border-bottom: 1px solid #ccc;
    width: calc(100% - 30px);
    padding: 15px 15px;
    
}

.chapter-btn > p{
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
}

.padding-15{
    padding: 15px;
}

.preface-text{
    font-size: 14px;
}

.bullet{
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #000000;
    display: inline-block;
    margin-right: 5px;
}

.book-meta p{
    font-size: 13px;
}

.custom-text-css{
    font-size: 14px;
    line-height: 20px;
}

/* New Page css */

.book-detail-logo{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.book-detail-menu{
    border: 1px solid #ccc;
}

.book-detail-menu button{
    display: flex;
    all: unset;
    border-bottom: 1px solid #ccc;
    width: calc(100% - 30px);
    padding: 10px 15px;
    font-size: 14px;
    color: #225ba7;
    cursor: pointer;
}

.book-detail-menu button > p{
    margin-bottom: 0px;
    font-size: 12px!important;
}

.black{
    color: #000000;
}

.section-btn{
    display: flex;
    all: unset;
    width: calc(100% - 30px);
    padding: 15px 15px;
    color: #2c3539;    
    display: flex;
    align-items: center;
    position: relative;
}

.section-btn > p{
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
}

.chapter-button{
    display: flex;
    all: unset;
    width: calc(100% - 30px);
    padding: 15px 15px;
    color: #225ba7;    
    display: flex;
    align-items: center;
    position: relative;
}

.chapter-button > p{
    margin-bottom: 0px;
    font-size: 14px;
}

.chapter-button:hover{
    text-decoration: underline;
}

.authors-name{
    color: #2c3539;
    font-size: 14px;
    margin: 10px 0px;
}

.book-main-title{
    font-size: 2rem;
    font-family: 'Lato';
}

.burder-link{
    color: #059adb;
    font-size: 13px;
}

.burder-link:hover{
    color: #225ba7;
    font-size: 13px;
}

.booksubdetails-action-btn{
    all: unset;
    font-size: 12px;
    color: #1b1b1b;
}